.homePage{
    width: 100%;
    position: static;
}
.pricesGroup{
    background-color: #141313;
    padding-top: 10em;
    padding-bottom: 10em;
}
.cardImage{
    width: 10em;
    margin-top: 20px;
}
.footerPage{
    background-color: #141313;
    color: white;
    padding-top: 3em;
    padding-bottom: 8em;
}
.footerSign{
    color: white;
    background-color: rgb(58, 54, 54);
    text-align: center;
}
.contentBanner{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}



